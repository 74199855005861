import React, { FunctionComponent } from "react";

import { QuestionGroup as FormQuestionGroup } from "../../db/models/Form";
import { renderedInlineHtmlOrLexical } from "../../shared/block-editor-data/html-or-lexical";
import { useTranslate } from "../translation/frontend";
import ErrorSummary from "./ErrorSummary";
import { PaginationWide } from "./Pagination";
import QuestionComponent from "./Question";
import SkipToEndButton from "./SkipToEndButton";
import { useForm } from "./context";
import { ValidityProvider } from "./useInvalidQuestions";

interface QuestionGroupProps {
  /** The page is available on the context, but we pass it through so that Typescript can assert what type of page it is */
  page: FormQuestionGroup;
}

const QuestionGroup: FunctionComponent<QuestionGroupProps> = ({ page }) => {
  const translate = useTranslate();
  const { answers } = useForm();

  const intro = page.groupIntro || (page.allowSkipToPage ? translate("skipToEndFallbackIntro") : null);
  return (
    <ValidityProvider>
      {intro ? (
        <section className="form-group-intro section">
          <div className="container--slim">
            <div
              className="content-styles allow-cross-form-links"
              dangerouslySetInnerHTML={{ __html: renderedInlineHtmlOrLexical(intro) }}
            />
          </div>
        </section>
      ) : null}
      {page.allowSkipToPage ? (
        <section className="form-group-intro form-group-intro__skip-to-end section">
          <div className="container--slim container--slim__skip-to-end">
            <SkipToEndButton disabled={false} />
          </div>
        </section>
      ) : null}
      <section className="form-group section">
        <div className="container--slim">
          <ErrorSummary questions={page.questions} answers={answers} />
          {page.questions.map((question) => (
            <QuestionComponent key={question.id} question={question} answers={answers} />
          ))}
        </div>
      </section>
      <PaginationWide />
    </ValidityProvider>
  );
};

export default QuestionGroup;
